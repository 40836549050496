import React, { useEffect, useState } from "react"
import axios from "axios"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
import { Backdrop, makeStyles, CircularProgress } from "@material-ui/core"

const NotFoundPage = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const [activeBackdrop, setActiveBackdrop] = useState(true)
  const [dataFromUrl, setDataFromUrl] = useState()
  const [loadingContent, setLoadingContent] = useState(true)
  const [bookUrl, setBookUrl] = useState()
  
  useEffect(() => {
    async function fetchData() {
      let pathFromSearchBar
      let pathFormSearchBarNoSlash
      let pathFormSearchBarWithSlash

      if (windowGlobal) {
        pathFromSearchBar = windowGlobal.location.href
        pathFormSearchBarWithSlash = pathFromSearchBar.endsWith("/") ? pathFromSearchBar : pathFromSearchBar + "/"
        pathFormSearchBarNoSlash = pathFromSearchBar.endsWith("/") ? pathFromSearchBar.slice(0, -1) : pathFromSearchBar
      }

      await axios
        .get(
          `${process.env.GATSBY_API_URL}/old-routes/?old_route=${pathFromSearchBar && pathFromSearchBar}&old_route=${
            pathFromSearchBar && pathFromSearchBar.substring(7)
          }&old_route=${pathFromSearchBar && pathFromSearchBar.substring(8)}&old_route=${
            pathFormSearchBarWithSlash && pathFormSearchBarWithSlash
          }&old_route=${pathFormSearchBarWithSlash.substring(7)}&old_route=${pathFormSearchBarWithSlash.substring(
            8
          )}&old_route=${pathFormSearchBarNoSlash && pathFormSearchBarNoSlash}&old_route=${
            pathFormSearchBarNoSlash && pathFormSearchBarNoSlash.substring(7)
          }&old_route=${pathFormSearchBarNoSlash && pathFormSearchBarNoSlash.substring(8)}`
        )
        .then(res => {
          setBookUrl(res.data.length > 0 && res.data[0].isOldBook && res.data[0].new_urls[0].new_url)
          setTimeout(() => {
            if (windowGlobal) {
              if (res.data.length > 0 && res.data[0].isOldBook) {
                windowGlobal.location = res.data[0].new_urls[0].new_url
              } else {
                windowGlobal.location = "/books/promotions/redirect/index.html"
              }
            }
          }, 200)
        })
        .catch(err => {
          console.log(err)
        })
        setLoadingContent(false)
        setActiveBackdrop(false)
    }
    fetchData()
    // setTimeout(() => {
    //   if (windowGlobal) {
    //     if (bookUrl !== false) {
    //       windowGlobal.location = bookUrl
    //       setLoadingContent(false)
    //       setActiveBackdrop(false)
    //     } else {
    //       windowGlobal.location = "/books/promotions/index.html"
    //       setLoadingContent(false)
    //       setActiveBackdrop(false)
    //     }
    //   }
    // }, 600)
  }, [])

  return (
    <>
      <SEO title={t("pages.404")} lang={i18n.language} />
      <Backdrop className={classes.backdrop} open={activeBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!bookUrl && !loadingContent && (
        <>
          <h1>{t("pages.404")}</h1>
        </>
      )}
    </>
  )
}

export default NotFoundPage

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))

const windowGlobal = typeof window !== "undefined" && window
